<template>
    <div v-if="dados && dados.length > 0 ">
        <b-overlay 
          v-if="loadingShow" 
          :show="loadingShow" 
          rounded="lg" 
          opacity="0.6"
        />
          <div class="subTitulo mr-2">Com base nos respondentes que apresentaram algum grau de depressão (escala 1 ou maior).</div>
          <vue-perfect-scrollbar class="scroll-area pr-2" :settings="settings">
            <div class="demo-vertical-spacing pt-1 mr-1 pb-1">
              <b-row
                v-for="item in dadosParaOComponenteFuncionar"
                :key="item.id"
                class="tamanho-minimo-row"
              >
                <b-col
                  xl="4"
                  lg="4"
                  md="12"
                  class="nome-barra"
                >
                  {{ item.conteudo }}
                </b-col>
                <b-col
                  xl="8"
                  lg="8"
                  md="12"
                >
                  <b-progress
                    :max="max"
                    class="progress-bar-info"
                  >
                    <b-progress-bar
                      v-if="item.totalizador >= porcentagemResponsivo"
                      :value="item.totalizador"
                      :label="item.totalizador + '%'"
                      class="barra-gradiente"
                    />
                    <b-progress-bar
                      v-else
                      :value="item.totalizador"
                      :label="''"
                      class="barra-gradiente"
                    />
                  </b-progress>
                </b-col>
              </b-row>
            </div>
          </vue-perfect-scrollbar>
      </div>
      
      <div
        v-else
        class="height-custom d-flex align-items-center justify-content-center"
      >
        <card-sem-dados :mensagemTipo="validarCardSemDados.tipagemErro"/>
      </div>
  </template>
  
  <script>
  import { BProgress, BCardTitle, BCardHeader, BProgressBar, BCardText, BCard, BRow, BCol, BOverlay } from 'bootstrap-vue'
  import VuePerfectScrollbar from 'vue-perfect-scrollbar'
  import CardSemDados from "./CardSemDados.vue";
  
  export default {
    components: {
      BCard,
      BProgress,
      BProgressBar,
      BCardText, 
      BCardHeader,
      BRow,
      BCol,
      VuePerfectScrollbar,
      BOverlay,
      BCardTitle,
      CardSemDados
    },
    props: {
      ApplicationData: {
        type: Object,
        required: true
      },
      dados: {
        type: Array,
        required: true
      }
    },
    data() {
      return {
        validarCardSemDados: {
          verificadorVazio: true,
          tipagemErro: 0
        },
        loadingShow: true,
        dadosParaOComponenteFuncionar: [],
        max: 100,
        porcentagemResponsivo: this.visibilidadeComprimento(),
        settings: {
          maxScrollbarLength: 70,
          wheelSpeed: 0.3,
          wheelPropagation: false
        }
      }
    },
    mounted(){
      this.buscaDadosCardAreaDificuldade();
      this.loadingShow = false;
  
      window.addEventListener('resize', () => {
        this.porcentagemResponsivo = this.visibilidadeComprimento();
      });
    },
    methods: {
      buscaDadosCardAreaDificuldade() {
        this.preparaMax(this.$props.dados)
        this.dadosParaOComponenteFuncionar = this.preparaPorcentagem(this.$props.dados)  
      },
      visibilidadeComprimento(){
        if(window.innerWidth >= 400 && window.innerWidth <= 470){
          return 10;
        }
        if(window.innerWidth < 400){
          return 15;
        }
        return 5;
      },
      preparaMax(arrayDados){
          let somatorio = 0
          for(const element of arrayDados){
              somatorio += element['totalizador']
          }
          this.max = somatorio
      },
      preparaPorcentagem(arrayDados){
          for(const element of arrayDados){
              element['totalizador'] = Math.floor((element['totalizador']/this.max)*100)
          }
          return arrayDados.sort((a, b) => b.totalizador - a.totalizador);
      }
    },
  }
  </script>
  <style scoped>

  .subTitulo{
      text-align: center;
      font-size: 13px;
      margin-bottom: 10px;
  }
  .nome-barra{
    margin-top:-5px;
    text-align: right;
    font-size: 15px;
  }
  .barra-gradiente{
    background-image: linear-gradient(to right, #3A60B7, #2C2679);
  }
  .scroll-area{
    height:260px;
  }
  .titulo_margem{
    margin-left: -20px;
  }
  .card-height{
    height: 340px;
  }
  
  .titulo_subtitulo_responsivo{
    margin-left: -20px;
    margin-top: -25px;
    margin-bottom: -15px;
  }
  .margem-titulo_subtitulo_responsivo{
    margin-top: 5px;
  }
  
  .margem-subtitulo_responsivo{
    margin: 5px -1.5rem 0 auto;
  }
  
  .tamanho-minimo-row{
    min-width:340px;
  }
  
  @media (max-width: 1600px) {
    .subTitulo{
      font-size: 8px;
      margin-bottom: 5px;
    }
  }
  
  @media (min-width: 1441px) and (max-width: 1465px)
  {
    .nome-barra{
      font-size: 11px !important;
    }
  }
  @media (min-width: 1441px) and (max-width: 1750px) {
    .col-xl-2{
      flex:0 0 25%;
      max-width:25%
    }
    .nome-barra{
      margin-top:-5px;
      text-align: right;
      font-size: 12px;
    }
    .col-xl-10{
      flex:0 0 75%;
      max-width:75%
    }
  }
  
  @media (min-width: 1200px) and (max-width: 1440px) {
    .col-xl-2{
      flex:0 0 33.3333333333%;
      max-width:33.3333333333%
    }
    .col-xl-10{
      flex:0 0 66.6666666667%;
      max-width:66.6666666667%
    }
    .nome-barra{
      font-size: 9px;
    }
  
    .subTitulo{
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
  
    @media (max-width: 1199px) {
      .nome-barra{
        text-align: left;
        font-size: 0.7rem;
      }
    }
  
  
    @media (min-width: 992px) and (max-width: 1199px){
      .tamanho-minimo-row{
        min-width:440px;
      }
    }

  
    @media (max-width: 767px){
      .card-height{
        height: 100%;
        margin-bottom: -1rem;
      }
    }
  
    @media (max-width: 458px){
      .subTitulo{
        margin-bottom: 0;
      }
    }
  </style>
  